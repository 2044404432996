import styled from "styled-components";
import colors from "../../shared/color";
import {animated} from "react-spring";

export const SuccessContainer = styled(animated.div)`

  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0 0 0;
  flex-direction: column;
  width: 100%;
`;

export const ThankYou = styled.div`
  font-size: 38px;
  color: ${colors.light_blue};
  font-weight: bold;
  padding: 0;
`;

export const Success = styled.div`
  font-size: 20px;
  color: ${colors.white};
  padding: 0 0 15px 0;
`;