import styled from 'styled-components';
import colors from "../../shared/color";
import { animated as a } from 'react-spring';
import { ReactComponent as ResponsiveIcon} from '../../assets/card-assets/cards-icons/RESPONSIVEICON.svg';
import { ReactComponent as SEOICON } from '../../assets/card-assets/cards-icons/SEOICON.svg';
import { ReactComponent as UNIKTICON } from '../../assets/card-assets/cards-icons/UNIKTICON.svg';
import { ReactComponent as HOSTINGICON } from '../../assets/card-assets/cards-icons/HostingIcon.svg';
import { ReactComponent as LOGINICON} from '../../assets/card-assets/cards-icons/LoginIcon.svg';
import { ReactComponent as FUNCTIONICON } from '../../assets/card-assets/cards-icons/funktionalitetIcon.svg'
import { ReactComponent as DATAICON } from '../../assets/card-assets/cards-icons/dataintegrationIcon.svg';
import { ReactComponent as FOTOICON } from '../../assets/card-assets/cards-icons/FotografiIcon.svg';
import { ReactComponent as LOGODESIGNICON } from '../../assets/card-assets/cards-icons/logodesignIcon.svg';
import { ReactComponent as UXUIICON } from '../../assets/card-assets/cards-icons/uxuidesign.svg';
import { ReactComponent as UDVIDETSEOICON } from '../../assets/card-assets/cards-icons/udvidet-seo.svg';
import { ReactComponent as WEBSOLUTIONICON } from '../../assets/card-assets/cards-icons/websolutions.svg';
import {ReactComponent as SPECIFICATIONICON} from '../../assets/process-icons/SpecificationsIcon.svg';
import breakpoints from "../../shared/breakpoints";

/*----------------FRONT of card styling----------------*/
export const TitleContainer = styled.h1`    
        font-size: 19px; 
        display: flex;      
        color: ${colors.white};
        letter-spacing: 2px;
        margin-bottom: 0;
        align-self: center; 
        justify-content: center;
        font-weight: 900;
        width: 100%;
        padding: 30px 0 20px 0;  

    ${breakpoints.mobile}  {
        font-size: 24px;
  
  }   
    ${breakpoints.tablet}  {
        font-size: 35px;
        margin-top: 5%;
        padding: 10px 0 30px 0;

        
  }
  
    ${breakpoints.desktop}  {
        font-size: 40px;
        margin-top: 5%;
        display: flex;      
        color: ${colors.white};
        letter-spacing: 2px;
        margin-bottom: 0;
        align-self: center;
        justify-content: center;
        font-weight: 900;
        width: 100%;    
  }
`;

export const CardImage = styled(a.div)`
  display: flex;
  justify-content: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  filter: contrast(70%);
  overflow: hidden;
  position: relative;
  transition: filter 0.5s cubic-bezier(.43,.41,.22,.91);
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    position: relative;
    cursor: pointer; 
    

    ${breakpoints.desktop}  {
    flex-direction: row;
    justify-content: space-evenly;
  }
    
`;

export const CardItem = styled(a.div)`
    width: 90%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: ${colors.tinted_black};
    border-radius: 3px;
    display: flex;
    align-content: center;
    margin-bottom: 20px;
       
    
    ${breakpoints.tablet}  {
      margin-bottom: 40px;
      width: 60%;
    }
        
    ${breakpoints.desktop}  {
        width: 33.333%; 
        margin-bottom: 30px;        
        align-items: center;
        flex-wrap: nowrap;
        
    &:hover {
      ${CardImage} {
        filter: contrast(100%);
      }
    }

  
    }
`;

export const CardTitle = styled.div`
    color: ${colors.white};
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    //flex: 1 1 auto;
    flex-direction: row;
    padding: 12px 12px 0 12px;
   font-family: 'Montserrat', sans-serif;
   
   ${breakpoints.mobile}  {
       font-size: 20px;
  } 
   
  ${breakpoints.tablet}  {
       font-size: 25px;
  }  

  ${breakpoints.desktop}  {
       font-size: 25px;
  } 
`;

export const CardText = styled.div`
    flex: 1 1 auto;
    font-size: 14px;
    line-height: 1.5;
    color: ${colors.white};
    padding: 10px 10px 0 10px;
    text-align:center;
    flex-wrap: nowrap;
    
    ${breakpoints.mobile}{
      font-size: 15px;
    
    } 

    ${breakpoints.tablet}{
      font-size: 15px;
    
    } 

    ${breakpoints.desktop}{
    flex: 1 1 auto;
    font-size: 16px;
    line-height: 1.5;
    //margin-bottom: 1.25rem;
    color: ${colors.white};
    padding: 10px 30px 10px 30px;
    text-align:center;
    flex-wrap: nowrap;
    }
`;



/*----------------BACK of card styling----------------*/
export const SpecificationsTitle = styled.div`
    color: ${colors.white};
    font-size: 14px;
    font-weight: 800;
    text-align: center;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 20px 15px 20px 15px;  
    
    
    ${breakpoints.tablet}{
        color: ${colors.white};
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
         padding: 20px 15px 20px 15px;
    }  

    ${breakpoints.desktop}{
        color: ${colors.white};
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding: 20px 20px 20px 20px; 
    }  
`;

export const SpecificationItem = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: center;
    align-content: center;
    width: 50%;
    color: ${colors.white};
    font-size: 14px;
    
    ${breakpoints.desktop}{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: center;
    align-content: center;
    width: 50%;
    color: ${colors.white};
    
    }
 `;

export const IconContainer = styled.div`
    width: 30%;
    display: flex;
    justify-content: right;
`;


export const SpecificationText = styled.div`
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px; 
`;

export const EnhanceText = styled.b`
    color: ${colors.light_blue}
  
`;

export const TitleBlue = styled.b`
  color: ${colors.light_blue};
  font-size: 16px;
  font-weight: 900;
  font-family: 'Montserrat', sans-serif;

  ${breakpoints.mobile}  {
    font-size: 20px;
  }

  ${breakpoints.tablet}  {
    font-size: 25px;
  }

  ${breakpoints.desktop}  {
    font-size: 25px;
  }
`;

export const ResponsivenessIcon  = styled(ResponsiveIcon)`
    height: 40px;
    width: 40px;
    padding-right: 20px;
    
    ${breakpoints.tablet}{
    height: 50px;
    width: 50px;
    padding-right: 20px;
    } 
    
    ${breakpoints.desktop}{
    height: 60px;
    width: 60px;
    padding-right: 20px;
    }
`;

export const SeoIcon = styled(SEOICON)`
        height: 40px;
        width: 40px;
        padding-right: 20px;
    
    ${breakpoints.tablet}{
        height: 50px;
        width: 50px;
        padding-right: 20px;
    }
  
`;

export const SpecificationIcon = styled(SPECIFICATIONICON)`
        height: 40px;
        width: 40px;
        padding-right: 20px;
    
    ${breakpoints.tablet}{
        height: 50px;
        width: 50px;
        padding-right: 20px;
    }
  
`;

export const UniktIcon = styled(UNIKTICON)`
        height: 40px;
        width: 40px;
        padding-right: 20px;

    ${breakpoints.tablet}{
        height: 50px;
        width: 50px;
        padding-right: 20px;
    }

    ${breakpoints.desktop}{
        height: 60px;
        width: 60px;
        padding-right: 20px;
    }
`;

export const HostingIcon = styled(HOSTINGICON)`
        height: 40px;
        width: 40px;
        padding-right: 20px;
    
    ${breakpoints.tablet}{
        height: 50px;
        width: 50px;
        padding-right: 20px;
    }
`;

export const LoginIcon = styled(LOGINICON)`
         height: 40px;
        width: 40px;
        padding-right: 20px;
    
    ${breakpoints.tablet}{
        height: 50px;
        width: 50px;
        padding-right: 20px;
    }
`;

export const FunctionIcon = styled(FUNCTIONICON)`
         height: 40px;
        width: 40px;
        padding-right: 20px;
    
    ${breakpoints.tablet}{
        height: 50px;
        width: 50px;
        padding-right: 20px;
    }
`;

export const DataIcon = styled(DATAICON)`
          height: 40px;
        width: 40px;
        padding-right: 20px;
    
    ${breakpoints.tablet}{
        height: 50px;
        width: 50px;
        padding-right: 20px;
    }
`;

export const FotoIcon = styled(FOTOICON)`
         height: 40px;
        width: 40px;
        padding-right: 20px;
    
    ${breakpoints.tablet}{
        height: 50px;
        width: 50px;
        padding-right: 20px;
    }
`;

export const LogoDesignIcon = styled(LOGODESIGNICON)`
        height: 40px;
        width: 40px;
        padding-right: 20px;
    
    ${breakpoints.tablet}{
        height: 50px;
        width: 50px;
        padding-right: 20px;
    }
`;

export const UiuxIcon = styled(UXUIICON)`
       height: 40px;
        width: 40px;
        padding-right: 20px;
    
    ${breakpoints.tablet}{
        height: 50px;
        width: 50px;
        padding-right: 20px;
    }
`;

export const WebsolutionIcon = styled(WEBSOLUTIONICON)`
       height: 40px;
        width: 40px;
        padding-right: 20px;
    
    ${breakpoints.tablet}{
        height: 50px;
        width: 50px;
        padding-right: 20px;
    }
`;

export const UdvidetSeoIcon = styled(UDVIDETSEOICON)`
       height: 40px;
        width: 40px;
        padding-right: 20px;
    
    ${breakpoints.tablet}{
        height: 50px;
        width: 50px;
        padding-right: 20px;
    }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 0 0 20px 0;
  
  ${breakpoints.desktop}{
  margin: 0 0 20px 0;
  }
`;

/*-------------Images for service cards-------------*/
export const ServiceCards = styled.img`
  width: 30%;
  height: 30%;
  margin-top: 15px;
`;
