import React from 'react';
import ReactCardFlip from 'react-card-flip';
import EkstraServices from '../../assets/card-assets/card-images/forecast.png'
import {
    CardImage,
    CardText,
    CardTitle,
    EnhanceText,
    SpecificationsTitle,
    ButtonContainer,
    TitleBlue, ServiceCards
} from "../../pages/service/service.styles";
import {NavLink} from "react-router-dom";
import Button from "../buttons/general-button/button.component";


class ExtraServicesWebsiteCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFlipped: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        event.preventDefault();
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
    }

    render() {
        return (
            <ReactCardFlip isFlipped={this.state.isFlipped}
                           flipSpeedBackToFront={0.5}
                           flipSpeedFrontToBack={0.5}
                           flipDirection="vertical">
                <div onClick={this.handleClick}>
                    <CardImage>
                        <ServiceCards src={EkstraServices} alt="" title=""/>
                    </CardImage>


                    <CardTitle>FIRMA<TitleBlue>RESEARCH</TitleBlue></CardTitle>
                    <CardText>Har din virksomhed brug for en <EnhanceText>researcher eller konsulent </EnhanceText>
                        til områder vedrørende data og optimering?
                        <ButtonContainer>
                            <Button serviceCard className="btn draw-border">Læs mere</Button>
                        </ButtonContainer>
                    </CardText>
                </div>
                <div>
                    <div onClick={this.handleClick}>
                        <CardTitle><TitleBlue>RESEARCH/KONSULENT</TitleBlue></CardTitle>
                        <SpecificationsTitle>
                            Vi kan hjælpe med at lave research og skabe idéer der kan være med til at forbedre din virksomhedsdrift.

                        </SpecificationsTitle>
                        <SpecificationsTitle>
                            Som researcher eller konsulent kan vi komme med foreslag og løsninger på hvordan din virksomhed
                            kan bruge deres data bedst muligt indenfor din branche.
                        </SpecificationsTitle>
                        <SpecificationsTitle>
                            Har du brug for hjælp til at finde optimering i din virksomhed? Generelt research og nye øjne på projekter?
                            Eller en helt anden type research/konsulent hjælp?  Lad os snakke om hvad vi kan gøre for dig?
                        </SpecificationsTitle>
                    </div>

                    <ButtonContainer>
                        <Button serviceCard className="btn draw-border"><NavLink to="/kontakt"> Lad os hjælpe dig</NavLink></Button>
                    </ButtonContainer>
                </div>

            </ReactCardFlip>
        );
    }
}

export default ExtraServicesWebsiteCard;