import React, {useEffect} from "react";
import {ContactPageContainer} from "../../components/common/Container.component";
import {
    ContactFormContainer,
    ContactTitle,
    ContactMessage,
    ContactTitleContainer,
    SmileyIcon,
    FormContainer

} from "./contact.styles";
import {useForm} from "react-hook-form";
import emailjs from "emailjs-com";
import ReactDOM from "react-dom";
import SuccessMessage from "../../components/success-message/success-message.component";
import {FaAsterisk} from "react-icons/all";
import {FormButtonComponent} from "../../components/buttons/form-button/form-button.component";
import './contact.styles.scss';
import Spinner from "../../components/spinner/spinner.component";
import {Helmet} from "react-helmet/es/Helmet";


function ContactPage() {

    useEffect(() => {
        window.scrollTo(0, 0)
    });

    const {register, handleSubmit, errors} = useForm();

    const onSubmit = data => {
        loadSpinner();
        emailjs.send('smtp_server', 'template_BlKOb6Rn', data, 'user_rjBExlrCb002jYCc3XGVF')
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
                console.log(data);
                ReactDOM.render(
                    <SuccessMessage/>
                    ,
                    document.getElementById('success')
                );
            }, function (error) {
                console.log('FAILED...', error);
            });
    };

    const loadSpinner = () => (
        ReactDOM.render(
            <Spinner/>,
            document.getElementById('spinner')
        )
    );


    return (
        <>
            <Helmet>
                <title>Prime Coding | Kontakt</title>
                <meta
                    name="description"
                    content="Skal vi være dine personlige nørder så udfyld vores kontakt formular og lad os starte et samarbejde"
                />
            </Helmet>
            <ContactPageContainer id='success'>


                <ContactTitleContainer>
                    <ContactTitle>Kontakt Os</ContactTitle>
                    <ContactMessage>Vi glæder os til at høre fra dig<SmileyIcon/></ContactMessage>
                </ContactTitleContainer>

                <ContactFormContainer>
                    <FormContainer onSubmit={handleSubmit(onSubmit)}>
                        <div className="group">
                            <label className="form-input-label">Navn</label>
                            <input
                                className="form-input"
                                type="text"
                                name="name"
                                ref={register({required: true})}
                            />
                            {errors.name &&
                            <span className="error-fill"><FaAsterisk size={12}/> Indtast venligst dit navn</span>}
                        </div>

                        <div className="group">
                            <label className="form-input-label">Email</label>
                            <input
                                className="form-input"
                                type="email"
                                name="email"
                                ref={register({required: true})}
                            />
                            {errors.email &&
                            <span className="error-fill"><FaAsterisk size={12}/> Indtast venligst din email</span>}
                        </div>

                        <div className="group">
                            <label className="form-input-label">Telefon</label>
                            <input
                                className="form-input"
                                type="tel"
                                name="phone"
                                ref={register({required: true})}
                            />
                            {errors.phone &&
                            <span className="error-fill"><FaAsterisk
                                size={12}/> Indtast venligst et telefon nummer</span>}
                        </div>

                        <div className="group">
                            <textarea className="form-input-area" name="info" type="text" ref={register}>

                            </textarea>
                            <label className="form-input-label"
                            >
                                Besked
                            </label>
                        </div>
                        <div id="spinner">
                            <FormButtonComponent onClick={loadSpinner}>
                                Send besked
                            </FormButtonComponent>
                        </div>
                    </FormContainer>


                </ContactFormContainer>

            </ContactPageContainer>
        </>
    )
}

export default ContactPage;