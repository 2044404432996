import styled from "styled-components";
import colors from "../../../shared/color";

export const FormButton = styled.button`
  width: 100%;
  height: 50px;
  background: ${colors.white};
  color: ${colors.background_black};
  font-size: 20px;
  border-radius: 8px;
  border: none;
  font-weight: 900;
  cursor: pointer;
  margin-bottom: 40px;
  transition: 0.3s ease-in-out;
  
&:hover{
    background: ${colors.light_blue};
    color: ${colors.white};
}
`;

