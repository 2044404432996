const colors = {
    background_black: "#070708",
    black: "#17191C",
    tinted_black: "#0f0f11",
    light_grey: "#383838",
    grey: "#243A40",
    white: "#FBFBFB",
    light_blue: "#60B0DE",
    secure_green: "#0b6e4f",
    passion_red: "#7e2d2c"

};

export default colors;