import React from 'react';
import ReactCardFlip from 'react-card-flip';
import {
    CardTitle,
    CardText,
    CardImage,
    SpecificationsTitle,
    EnhanceText,
    ButtonContainer,
    TitleBlue,
    ServiceCards
} from "../../pages/service/service.styles";
import Website from "../../assets/card-assets/card-images/infographic.png";
import {NavLink} from "react-router-dom";
import Button from '../buttons/general-button/button.component';


class DataVisualisering extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFlipped: false,
            hide: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        event.preventDefault();
        this.setState(prevState => ({isFlipped: !prevState.isFlipped}));
    }

    render() {
        return (
            <ReactCardFlip isFlipped={this.state.isFlipped}
                           flipSpeedBackToFront={0.5}
                           flipSpeedFrontToBack={0.5}
                           flipDirection="vertical">
                <div onClick={this.handleClick}>
                    <CardImage>
                        <ServiceCards src={Website} alt="Datavisualisering billede" title="Ikon af datavisualisering"/>
                    </CardImage>
                    <CardTitle>DATA<TitleBlue>VISUALISERING</TitleBlue></CardTitle>
                    <CardText>
                        Visualisering kan være en ekstraordinær måde at forstå og kommunikere din data på en <EnhanceText>effektiv
                            og simpel måde</EnhanceText>.
                        <br/>
                        <ButtonContainer>
                            <Button serviceCard className="btn draw-border">Læs mere</Button>
                        </ButtonContainer>
                    </CardText>
                </div>
                <div>
                    <div onClick={this.handleClick}>
                        <CardTitle><TitleBlue>VISUALISERING</TitleBlue></CardTitle>
                        <SpecificationsTitle>
                            Visualisering af data har en stor efterspørgelse hos virksomheder, da det skaber
                            værdi at kunne visualisere ens data og få præcis opfattelse af hvad dataet fortæller og hvordan
                            den ser ud.
                        </SpecificationsTitle>

                        <SpecificationsTitle>
                            Datavisualisering kan bruges både internt i en virksomhed men også externt, til kunder.
                        </SpecificationsTitle>

                        <SpecificationsTitle>
                            Har du brug for interaktive grafer? Dashboards? Eller noget helt andet? Lad os tage en uforpligtende snak om det.
                        </SpecificationsTitle>
                    </div>
                        <ButtonContainer>
                            <Button serviceCard className="btn draw-border" as="a" href="/kontakt"><NavLink to="/kontakt"> Skal vi tage en snak?</NavLink></Button>
                        </ButtonContainer>
                    </div>
            </ReactCardFlip>


        );
    }
}

export default DataVisualisering;