import React from 'react';
import {GlobalStyle} from "./global.styles";
import Footer from "./components/footer/footer.component";
import {MainContainer} from "./components/common/Container.component";
import Navbar from "./components/navbar/nav/nav.component";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import Particles from "react-particles-js";
import { Switch, Route, withRouter } from 'react-router-dom';
import HomePage from "./pages/homepage/homepage.component";
import ProcessPage from "./pages/process/process.component";
import ServicePage from "./pages/service/service.component";
import ContactPage from "./pages/contact/contact.component";
import Cookies from "./pages/cookies/cookies.component";



class App extends React.Component {
    constructor() {
        super();

        this.state = {
            navbarOpen: false,
            showSuccess: true,
        }
    }

    handleNavbar = () => {
        this.setState({
            navbarOpen: !this.state.navbarOpen
        });
    };


    render() {
        const {location} = this.props;

        return (
        <div>
                <div>
                    <MainContainer>
                        <div id="dust">
                            <Particles params={{
                                "particles": {
                                    "number": {
                                        "value": 800,
                                        "density": {
                                            "enable": true,
                                            "value_area": 800
                                        }
                                    },
                                    "color": {
                                        "value": "#60B0DE"
                                    },
                                    "shape": {
                                        "type": "circle",
                                        "stroke": {
                                            "width": 0,
                                            "color": "#60B0DE"
                                        },

                                    },
                                    "opacity": {
                                        "value": 0.3,
                                        "random": true,
                                        "anim": {
                                            "enable": true,
                                            "speed": 1,
                                            "opacity_min": 0.1,
                                            "sync": false
                                        }
                                    },
                                    "size": {
                                        "value": 3,
                                        "random": true,
                                        "anim": {
                                            "enable": true,
                                            "speed": 2,
                                            "size_min": 0.1,
                                            "sync": false
                                        }
                                    },
                                    "line_linked": {
                                        "enable": false
                                    },
                                    "move": {
                                        "enable": true,
                                        "speed": 1,
                                        "direction": "none",
                                        "random": false,
                                        "straight": false,
                                        "out_mode": "out",
                                        "bounce": false,
                                        "attract": {
                                            "enable": false,
                                            "rotateX": 600,
                                            "rotateY": 1200
                                        }
                                    }
                                },
                                "interactivity": {
                                    "detect_on": "canvas",
                                    "events": {
                                        "onhover": {
                                            "enable": false,
                                        },
                                        "onclick": {
                                            "enable": true
                                        },
                                        "resize": false
                                    }
                                },
                                "retina_detect": true
                            }}
                            />
                        </div>

                        <GlobalStyle/>

                        <Navbar
                            navbarState={this.state.navbarOpen}
                            handleNavbar={this.handleNavbar}
                        />
                        <Route render={({location}) => (
                            <TransitionGroup>
                                <CSSTransition
                                    key={location.key}
                                    classNames="fade"
                                    timeout={800}
                                >
                                    <Switch>
                                        <Route exact path='/' component={HomePage} />
                                        <Route exact path='/proces' component={ProcessPage} />
                                        <Route exact path='/services' component={ServicePage}/>
                                        <Route exact path='/kontakt' component={ContactPage}/>
                                        <Route exact path='/cookies' component={Cookies} />
                                    </Switch>
                                </CSSTransition>
                            </TransitionGroup>
                        )}
                        />
                        {location.pathname !== "/" && <Footer/>}
                    </MainContainer>
                </div>
        </div>
        );
    }
}

export default withRouter(App);
