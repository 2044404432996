import React from 'react'

import logo from '../../../assets/logo/primecoding-logo.svg';
import Blacklogo from '../../../assets/logo/logo-black-blue.svg';

import { WhiteImage, BlackImage } from "./brand.styles";

const Brand = () => {
    return (
        <>
            <WhiteImage src={logo} alt="Prime Coding Logo" title="Logo af virksomheden Prime Coding" />
            <BlackImage src={Blacklogo} alt="Black Prime Coding Logo" title="Logo af virksomheden Prime Coding" />
        </>
    )
};

export default Brand
