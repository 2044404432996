import styled from "styled-components";
import {animated} from "react-spring";
import colors from "../../../shared/color";
import breakpoints from "../../../shared/breakpoints";

export const NavBar = styled(animated.nav)`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 15;
    background: ${colors.background_black};
    font-size: 1.4rem;
  
   ${breakpoints.tablet}  {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      font-size: 1.1rem;
      background: ${colors.background_black};
  }

  ${breakpoints.desktop}  {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      background: transparent;
      z-index: 10;
      font-size: 1.4rem;
  }
`;

export const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto;
  padding: 0 2rem 0 1rem;
  justify-content: space-between;
  height: 5rem;
`;

export const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;
  font-size: 18px;
  
  ${breakpoints.xs_mobile} {
    display: none;
  }
  
  ${breakpoints.mobile} {
    display: none;
  }
  
  ${breakpoints.tablet} {
    display: none;
  }
  
  ${breakpoints.desktop} {
    display: flex;
  }
  
  & a {
    color: ${colors.white};
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid transparent;
    transition: all 300ms linear 0s;
    -webkit-transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;
    padding-bottom: 7.5px;
    margin: 30px 1.5rem 20px 1.5rem;

    &:hover {
      color: ${colors.light_blue};
      border-bottom: 3px solid ${colors.light_blue};
    }
  }
  
  .active { 
    color: #46bbf2;
    border-bottom: 3px solid ${colors.light_blue};
  }
`;

export const BurgerWrapper = styled.div`
  margin: auto 0;
  
  ${breakpoints.desktop} {
    display: none;
  }
`;
