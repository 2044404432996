import React, {useEffect} from 'react';
import {ServicePageContainer} from "../../components/common/Container.component";
import DataVisualisering from "../../components/service_cards/dataVisualisering.component";
import DataRensning from "../../components/service_cards/consultantWebsiteCard.component";
import DataModeller from "../../components/service_cards/administrativeWebsiteCard.component";
import VirksomhedResearch from "../../components/service_cards/extraServicesWebsiteCard.component";
import {
    TitleContainer,
    CardContainer,
    CardItem,
} from "./service.styles";
import MetaTags from 'react-meta-tags';

const ServicePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <MetaTags>
                <title>Prime Coding | Services</title>
                <meta name="description" content="Vi tilbyder professionelle hjemmesider, webshops og administrative systemer efter kundens behov." />
            </MetaTags>



            <ServicePageContainer>
                <TitleContainer>Hvordan kan vi hjælpe dig?</TitleContainer>
                <CardContainer>
                    <CardItem>
                        <DataVisualisering/>
                    </CardItem>

                    <CardItem>
                        <DataModeller/>
                    </CardItem>
                </CardContainer>
                <CardContainer>
                    <CardItem>
                        <DataRensning/>
                    </CardItem>
                    <CardItem>
                        <VirksomhedResearch/>
                    </CardItem>
                </CardContainer>
            </ServicePageContainer>
        </>
    );
};

export default ServicePage;
