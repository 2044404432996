import styled from 'styled-components';
import { animated } from 'react-spring';
import { AiOutlineClose } from "react-icons/all";
import colors from "../../../shared/color";
import breakpoints from "../../../shared/breakpoints";

export const CloseIcon = styled(AiOutlineClose)`
    width: 100px;
    color: #46bbf2;
    cursor: pointer;
`;


export const CollapseWrapper = styled(animated.div)`
  z-index: 30;
  background: ${colors.background_black};
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  height: 100vh;
  position: relative;
`;

export const NavItem = styled.div`
`;

export const NavLinks = styled.div`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
    
  & li {
    transition: all 300ms linear 0s;
  }

  & a {
    font-size: 1.4rem;
    line-height: 2;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    
    ${breakpoints.xs_mobile} {
      font-size: 1.5rem;
    }
    
    ${breakpoints.mobile} {
      font-size: 1.5rem;
    }
    
    ${breakpoints.tablet} {
      font-size: 1.8rem;
    }

    &:hover {
      color: ${colors.light_blue};
      border-bottom: 3px solid #46bbf2;
    }
  }
  
  .active {
    color: ${colors.light_blue};
    border-bottom: 3px solid ${colors.light_blue};
  }
`;
