import React from "react";
import {SuccessContainer, Success, ThankYou} from "./success-message.styles";
import {useSpring} from "react-spring";

const SuccessMessage = () => {

const barAnimation = useSpring({
    from: {transform: 'translate3d(0, -2rem, 0)'},
    transform: 'translate3d(0, 0, 0)',
});

return (
    <SuccessContainer style={barAnimation}>
        <ThankYou>
            Tusind Tak,
        </ThankYou>
        <Success>
            for din henvendelse.
        </Success>
        <Success>
            Vi vender tilbage snarest
        </Success>
        <Success>
            <b>>> Prime Coding </b>
        </Success>
    </SuccessContainer>
)
};

export default SuccessMessage;