import React from 'react';

import {useSpring} from 'react-spring';
import {CollapseWrapper, NavLinks, NavItem} from "./collapse_menu.styles";
import {NavLink} from "react-router-dom";
import {CloseIcon} from "./collapse_menu.styles";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import './collapse_menu.styles.scss';

const CollapseMenu = (props) => {
    const {open,} = useSpring({open: props.navbarState ? 0 : 1});

    if (props.navbarState === true) {
        return (
            <TransitionGroup>
                <CSSTransition classNames="fade" timeout={4500}>
                    <CollapseWrapper style={{
                        transform: open.interpolate({
                            range: [0, 0.2, 0.3, 1],
                            output: [0, -20, 0, -200],
                        }).interpolate(openValue => `translate3d(0, ${openValue}px, 0`),
                    }}
                    >
                        <NavLinks activeClassName="active">
                            <NavItem><NavLink to="/" onClick={props.handleNavbar}
                                              exact={true}>Forside</NavLink></NavItem>
                             <NavItem><NavLink to="/proces" onClick={props.handleNavbar}>Proces</NavLink></NavItem>
                            {/*<NavItem><NavLink to="/services" onClick={props.handleNavbar}>Services</NavLink></NavItem>*/}
                            {/*<NavItem><NavLink to="/om_os" onClick={props.handleNavbar}>Om Os</NavLink></NavItem>*/}
                            <NavItem><NavLink to="/kontakt" onClick={props.handleNavbar}>Kontakt</NavLink></NavItem>
                            <NavItem onClick={props.handleNavbar}><CloseIcon size={50} color=""/></NavItem>
                        </NavLinks>
                    </CollapseWrapper>
                </CSSTransition>
            </TransitionGroup>
        );
    }
    return null;
};

export default CollapseMenu;

