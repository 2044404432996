import styled from "styled-components";
import breakpoints from "../../shared/breakpoints";
import './common.styles.scss';



// MainContainer for whole project (Used in App.js)
export const MainContainer = styled.body`
      position: relative;
      z-index: 0;
      display: flex;
      height: 100%;
      flex-direction: column;
`;

// Primary reuseable container that other containers extends
export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const HomePageContainer = styled(Container)`
  flex-direction: column;

  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  height: 100vh; 
`;

export const ServicePageContainer = styled(Container)`
   align-items: center;
   justify-content: center;
   flex-direction: column;
   margin-top: 20%;
   width: 100%;
   
   ${breakpoints.tablet}  {
        margin-top: 10%;
  }
  
  ${breakpoints.desktop}  {
        margin-top: 5%;

  }
`;

export const AboutPageContainer = styled(Container)`
        flex-direction: column;
        margin-top: 25%;
        
         ${breakpoints.mobile}  {
        margin-top: 10%;
        width: 100%;
  } 

    ${breakpoints.tablet}  {
           
            width: 100%;
      }
      
    ${breakpoints.desktop}  {
        flex-direction: column;
        align-items: start;
        margin-top: 5%;
        width: 100%;
  }
`;

export const ContactPageContainer = styled(Container)`
        flex-direction: column;         
        align-items: center;
        margin-top: 30%;
        
    ${breakpoints.mobile}  {
        margin-top: 25%;
        width: 100%;
  } 

    ${breakpoints.tablet}  {
            margin-top: 20%;
            width: 100%;
      }
      
    ${breakpoints.desktop}  {
        flex-direction: row;
        align-items: start;
        margin-top: 5%;
        width: 100%;
  }
`;

export const ProcessPageContainer = styled(Container)`
        display: flex;
        flex-direction: column;
        margin-top: 25%;
        width: 100%;   
    
    ${breakpoints.mobile}  {
        margin-top: 20%;
    } 

    ${breakpoints.tablet}  {
        margin-top: 12%;
      }
      
    ${breakpoints.desktop}  {
        margin-top: 8%
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7.5% 2.5% 2.5% 2.5%;
  z-index: 30;
`;

export const CookiesContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  margin-top: 8%;
  justify-content: space-evenly;
  overflow-x: hidden;
  
  ${breakpoints.xs_mobile} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20% 0 0 0;
  }
  
  ${breakpoints.mobile} {
    margin: 20% 0 0 0;
  }
  
  ${breakpoints.tablet} {
    margin: 12% 0 0 0;
  }
  
  ${breakpoints.desktop} {
    margin: 8% 0 0 0;
  }
`;

