import React from 'react';
import styled from "styled-components";
import breakpoints from "../../../shared/breakpoints";

const StyledButton = styled.div`
        background: none;
        border: 3px;
        border-radius: 3px;
        line-height: 1.5;
        letter-spacing: 0.05rem;
        font: 700 13px 'Roboto Slab', sans-serif;

        justify-content: center;
        align-items: center;
        display: flex;
        opacity: 1;
        margin-top: ${({ serviceCard }) => (serviceCard ? '20px' : '0')};
        height:  ${({ serviceCard }) => (serviceCard ? '40px' : '40px')};
        width: ${({ serviceCard }) => (serviceCard ? '80%' : '130px')};
  
    &:focus { outline: 2px dotted #E3E3EB; }
     
    ${breakpoints.mobile}{
        font-size: 16px;
        height:  ${({ serviceCard }) => (serviceCard ? '40px' : '50px')};
        width: ${({ serviceCard }) => (serviceCard ? '80%' : '140px')};
    }  
        
    ${breakpoints.tablet}{
        cursor: pointer;
        font-size: ${({ serviceCard }) => (serviceCard ? '16px' : '20px')};
        width: ${({ serviceCard }) => (serviceCard ? '80%' : '160px')};
    } 
        
    ${breakpoints.desktop}{
      
        height: 55px;
        width: ${({ serviceCard }) => (serviceCard ? '80%' : '180px')};
    }
`;

// White button with blue hover, used throughout the app
// Primary button is used for service cards
// All else is used on front page and process
const Button = ({serviceCard, children}) => {
    return (
        <StyledButton serviceCard={serviceCard} className="btn draw-border">{children}</StyledButton>
    )
};

export default Button;