import styled from "styled-components";
import colors from "../../shared/color";
import {Link} from "react-router-dom";
import breakpoints from "../../shared/breakpoints";


export const CompanyContent = styled.div`
  display: flex;
  
  ${breakpoints.xs_mobile} {
    flex-direction: column;
  }
  
  ${breakpoints.mobile} {
    flex-direction: column;
  }
  
  ${breakpoints.tablet} {
    flex-direction: column;
  }
  
  ${breakpoints.desktop} {
    flex-direction: row;
  }
`;


export const CompanyName = styled.b`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16pt;
  color: white;
  margin-bottom: 15px;
`;


export const CompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  
  ${breakpoints.xs_mobile} {
    margin: 0 0 50px 25px;
  }
  
  ${breakpoints.mobile} {
    margin: 0 0 50px 25px;
  }
`;

export const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: space-between;
  
  ${breakpoints.xs_mobile} {
    margin: 0 0 50px 25px;
  }
  
  ${breakpoints.mobile} {
    margin: 0 0 50px 25px;
  }
  
  ${breakpoints.desktop} {
      margin: 0 0 0 100px;
  }
`;

export const Tider = styled.p`
  margin-bottom: 0;
`;

export const ContactTitle = styled.b`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16pt;
  color: white;
`;

export const TelephoneLink = styled.a`
  color: white;
  text-decoration: none;
`;

export const MailLink = styled.a`
  text-decoration: none;
  color: ${colors.light_blue};
`;

export const Cvr = styled.p`
  color: white;
  margin-bottom: 0;
`;

export const Break = styled.hr`
  color: ${colors.light_blue};
  width: 100%;
  filter: opacity(20%);
  margin: 30px 0;
`;

export const LowerFooter = styled.div`
  display: flex;
  flex-direction: row;
  
  ${breakpoints.xs_mobile} {
    margin: 0 0 0 25px;
  }
  
  ${breakpoints.mobile} {
    margin: 0 0 0 25px;
  }
`;

export const BusinessContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BusinessLink = styled.a`
  color: white;
  text-decoration: none;
  padding: 0 15px 0 0;
  transition: 0.3s ease-in-out;
  
  &:hover {
    color: ${colors.light_blue};
    cursor: pointer;
  }
`;

export const FooterLink = styled(Link)`
  color: white;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  font-size: 16px;
  
  &:hover {
    color: ${colors.light_blue};
    cursor: pointer;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  ${breakpoints.xs_mobile} {
    margin: 0 0 0 25px;
  }
  
  ${breakpoints.mobile} {
    margin: 0 0 0 25px;
  }
  
  ${breakpoints.desktop} {
    margin: 0 0 0 100px;  
  }
`;