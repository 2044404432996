import React from "react";
import colors from "../../shared/color";
import {Wave} from "./spinner.styles";

function Spinner() {


    return(
            <Wave size={30} color={colors.white} />
    )
}

export default Spinner;