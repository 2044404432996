import {createGlobalStyle} from "styled-components";
import colors from "./shared/color";

export const GlobalStyle = createGlobalStyle`
    * {
        font-family: Source Sans Pro,sans-serif;
        
    body {
        background: ${colors.background_black};
    }
    a {
      text-decoration: none;
      color: inherit;

    }
    
    input,
    textarea,
    button,
    select,
    a {
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
    }
    
    
`;
