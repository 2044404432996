import styled from "styled-components";
import colors from "../../shared/color";
import breakpoints from "../../shared/breakpoints";

import {FaSmileBeam} from "react-icons/all";

export const ContactTitle = styled.h1`
    display: flex;
    justify-content: left;
    font-size: 40px;
        font-weight: 900;
    letter-spacing: 2px;
    margin: 0;  
    text-align: center;
    color: ${colors.white}; 
    flex-direction: row;
 



    }
`;

export const ContactMessage = styled.span`
  text-align: left;
  font-size: 18px;
  color: ${colors.white};  
`;

/*----------------CONTAINER FOR CONTACT FORM----------------*/
export const ContactFormContainer = styled.div`    
        width: 65%;
        flex-direction: row;
        align-items: center;
        
    ${breakpoints.mobile}{
      width: 65%;
    }
    
    ${breakpoints.tablet}{
      width: 50%;
    }

     ${breakpoints.desktop}  {
        display: flex;
        margin-bottom: 20px;
        margin-top: 5%;
        height: 100%;
        width: 50%;
        flex-direction: column;
        align-items: start;
      }
    
`;

/*----------------CONTAINER FOR CONTACT TITLE AND SUBTITLE----------------*/
export const ContactTitleContainer = styled.div` 
        margin-bottom: 5px;      
        width: 100%;  
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;       
 
      ${breakpoints.desktop}  {
        display: flex;
        margin-bottom: 20px;
        width: 60%;
        height: 100%;
        flex-direction: column;
        margin-top: 5%;   
           
      }
   
`;

export const FormContainer = styled.form` 

      ${breakpoints.desktop}  {
            width: 50%;
      }
   
`;

export const SmileyIcon = styled(FaSmileBeam)`
  height: 20px;
   width: 20px;
   padding: 0 0 2px 5px;
  color: ${colors.light_blue}
  vertical-align: middle;
`;