import styled from "styled-components";
import breakpoints from "../../../shared/breakpoints";


export const WhiteImage = styled.img`
  height: 40%;
  margin: auto 0;
  padding-top: 24px;

  ${breakpoints.mobile}  {
    height: 58%;
  }
  
  ${breakpoints.tablet}  {
    height: 45%;
  }
  
  ${breakpoints.desktop}  {
    height: 58%;
  }

`;

export const BlackImage = styled.img`
   display: none;

`;
