import React from 'react';
import ReactCardFlip from 'react-card-flip';
import {
    CardImage,
    CardText,
    CardTitle,
    SpecificationsTitle,
    EnhanceText,
    ButtonContainer,
    ServiceCards,
    TitleBlue
} from "../../pages/service/service.styles";
import {NavLink} from "react-router-dom";
import ConsultantImage from '../../assets/card-assets/card-images/computer.png';
import Button from "../buttons/general-button/button.component";


class WebshopWebsiteCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFlipped: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        event.preventDefault();
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
    }

    render() {
        return (
            <ReactCardFlip isFlipped={this.state.isFlipped}
                           flipSpeedBackToFront={0.5}
                           flipSpeedFrontToBack={0.5}
                           flipDirection="vertical">
                <div onClick={this.handleClick}>
                    <CardImage>
                        <ServiceCards src={ConsultantImage}/>
                    </CardImage>
                    <CardTitle>DATA<TitleBlue>RENSNING</TitleBlue></CardTitle>
                    <CardText>
                        <EnhanceText>Data rensning</EnhanceText> er når ens data bliver "renset for fejl" og formatteret
                        så det kan bruges til generelle formål med succes.


                        <ButtonContainer>
                            <Button serviceCard className="btn draw-border">Læs mere</Button>
                        </ButtonContainer>
                    </CardText>
                </div>
                <div>
                    <div onClick={this.handleClick}>
                        <CardTitle><TitleBlue>RENSNING</TitleBlue></CardTitle>
                        <SpecificationsTitle>
                            Data skal behandles før det kan bruges til et bestemt formål. Dette formål kan være at laver data visualisering,
                            lave dataudtræk, udføre machine learning modeller og meget mere.

                        </SpecificationsTitle>

                        <SpecificationsTitle>
                            Denne process kan være uhåndgribelig, da det kan være svært at sætte sig ind i
                            hvordan ens data skal renses, og hvordan.
                        </SpecificationsTitle>

                        <SpecificationsTitle>
                            Vi hjælper med at gøre denne data rensning process smertefri, enten
                            ved at vejlede i hvordan dataet skal renses, eller med selve processen af at rense dataet.
                            Lad os tage en uforpligtende snak om det.
                        </SpecificationsTitle>
                    </div>
                    <ButtonContainer>
                        <Button serviceCard className="btn draw-border" as="a" href="/kontakt">  <NavLink to="/kontakt">Fortæl os om dit behov</NavLink></Button>
                    </ButtonContainer>
                </div>

            </ReactCardFlip>
        );
    }
}

export default WebshopWebsiteCard;