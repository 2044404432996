import React from 'react';
import {
    FooterContainer,
} from "../common/Container.component";
import './footer.styles';
import {
    CompanyInfo,
    CompanyName,
    ContactInfo,
    TelephoneLink,
    ContactTitle,
    MailLink,
    Break,
    CompanyContent,
    LowerFooter,
    BusinessContent,
    BusinessLink,
    Cvr,
    FooterLink,
    CompanyContainer
} from './footer.styles';

const Footer = () => {
    return (
        <FooterContainer>

            <Break/>
            <CompanyContent>

                <CompanyContainer>

                    <CompanyName>Prime Coding ApS</CompanyName>
                    <CompanyInfo>
                        Campusvej 1, 8.,-12
                        <br/>
                        5230 Odense M
                    </CompanyInfo>
                    <Cvr>CVR: 41 62 83 08</Cvr>
                </CompanyContainer>
                <ContactInfo>
                    <ContactTitle>Kontakt</ContactTitle>
                    <MailLink href="mailto: kontakt@primecoding.dk">kontakt@primecoding.dk</MailLink>
                    <strong><TelephoneLink href="tel: +4551915564">+45 31 31 97 45</TelephoneLink></strong>
                    <p></p>
                    <p></p>
                    {/*<MailLink href="https://www.linkedin.com/company/42094911/"><i className="fab fa-linkedin-in">link</i></MailLink>*/}

                </ContactInfo>

                {/*<LinkContainer>
                    <FooterLink to="/">Forside</FooterLink>
                    <FooterLink to="/proces">Proces</FooterLink>
                    <FooterLink to="/services">Services</FooterLink>
                    <FooterLink to="/om_os">Om Os</FooterLink>
                    <FooterLink to="/kontakt">Kontakt</FooterLink>
                </LinkContainer>*/}
            </CompanyContent>

            <LowerFooter>
                <BusinessContent>
                    <BusinessLink><FooterLink to="/cookies">Cookies og Privatlivspolitik</FooterLink></BusinessLink>
                </BusinessContent>
            </LowerFooter>
        </FooterContainer>
    );
};

export default Footer;
