import styled from "styled-components";
import colors from "../../shared/color";
import {ReactComponent as MEETINGICON} from '../../assets/process-icons/MeetingIcon.svg';
import {ReactComponent as PLANNINGICON} from '../../assets/process-icons/PlanningIcon.svg';
import {ReactComponent as CONSTRUCTIONICON} from '../../assets/process-icons/ConstructionIcon.svg';
import {ReactComponent as SPECIFICATIONICON} from '../../assets/process-icons/SpecificationsIcon.svg';
import {ReactComponent as STRATEGICPLANNING} from '../../assets/process-icons/StrategicPlanning.svg';
import {ReactComponent as TECHNICALASSESSMENT} from '../../assets/process-icons/TechnicalAssessment.svg';
import './process.styles.scss';
import breakpoints  from "../../shared/breakpoints";
import Handshake from '../../assets/process-icons/handshake.svg';

export const Row = styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
  

    ${breakpoints.tablet}  {
        display: flex;
        flex-direction: row;
        width: 100%;
      }
      
    ${breakpoints.desktop}  {
        display: flex;
        flex-direction: row;
        width: 100%;
  }  
`;

export const BlueColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
  align-self: center;
  
  
  ${breakpoints.desktop}{
    width: 65%;
  }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

/*----------------ICONS----------------*/
export const MeetingIcon = styled(MEETINGICON)`
    display: none;
    
     ${breakpoints.tablet}{
        height: 55px;
        width: 55px;
        padding-right: 10px;
        padding-top: 5px;
        display:flex;
    }
    
    ${breakpoints.desktop}{
        height: 60px;
        width: 60px;
        padding-right: 10px;
        margin-bottom: 3px;
    }
`;

export const SpecificationIcon = styled(SPECIFICATIONICON)`
    display: none;
   
    ${breakpoints.tablet}{
        height: 55px;
        width: 55px;
        padding-right: 10px;
        padding-top: 5px;
        display:flex;
    }

${breakpoints.desktop}{
         height: 55px;
        width: 55px;
        padding-right: 10px;
        padding-top: 5px;
    }
`;

export const PlanningIcon = styled(PLANNINGICON)`
        display: none;
   
    ${breakpoints.desktop}{
        height: 50px;
        width: 50px;
        padding-right: 20px;
    }
`;

export const StrategicPlanning = styled(STRATEGICPLANNING)`
        display: none;
   
   ${breakpoints.tablet}{
        height: 55px;
        width: 55px;
        padding-right: 10px;
        padding-top: 5px;
        display:flex;
    }
    
    ${breakpoints.desktop}{
        height: 55px;
        width: 55px;
        padding-right: 10px;
        padding-top: 5px;
        display: flex;
    }
`;

export const TechnicalAssessment = styled(TECHNICALASSESSMENT)`
       display: none;
   
   ${breakpoints.tablet}{
        height: 55px;
        width: 55px;
        padding-right: 10px;
        padding-top: 5px;
        display:flex;
    }
    
    ${breakpoints.desktop}{
        height: 55px;
        width: 55px;
        padding-right: 10px;
        padding-top: 5px;
    }
`;

export const ConstructionIcon = styled(CONSTRUCTIONICON)`
         display: none;
   
    ${breakpoints.desktop}{
    height: 50px;
    width: 50px;
    padding-right: 20px;
    padding-top: 8px;
    }
`;

export const ButtonItem = styled.div`
        background: none;
        border: 3px;
        border-radius: 3px;
        cursor: pointer;
        line-height: 1.5;
        letter-spacing: 0.05rem;
        font: 700 13px 'Roboto Slab', sans-serif;
        height: 43px;
        width: 250px;
        justify-content: center;
        align-items: center;
        display: flex;
        opacity: 1;
  
    &:focus { outline: 2px dotted #E3E3EB; }
    
    a {
        text-decoration: none;
        color: inherit;
    }
    ${breakpoints.mobile}{
        font: 700 16px 'Roboto Slab', sans-serif;
        height: 43px;
        width: 250px;
    }  
        
    ${breakpoints.tablet}{
        font: 700 20px 'Roboto Slab', sans-serif;
    height: 55px;
        width: 300px;
    } 
        
    ${breakpoints.desktop}{
       background: none;
        border: 3px;
        border-radius: 3px;
        cursor: pointer;
        line-height: 1.5;
        font: 700 1.2rem 'Roboto Slab', sans-serif;
        letter-spacing: 0.05rem;
        height: 55px;
        width: 300px;
        justify-content: center;
        align-items: center;
        display: flex;
        opacity: 1;
    }
`;

/*--------------------------------*/
export const AboutTitle = styled.h1`
    font-size: 20px;
    margin-top: 35px;
    opacity: 1;
    position: relative;
    color: #E3E3EB;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
    display: inline-block;
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 10px;
    

    ${breakpoints.tablet}  {
         font-size: 35px;
        margin-top: 50px;
        opacity: 1;
        position: relative;
        color: #E3E3EB;
        font-weight: bold;
        text-align: left;
        padding-bottom: 10px;
        display: inline-block;
        width: 100%;
        align-self: flex-end;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
      
    ${breakpoints.desktop}  {

  }
`;

export const ProcessSubtitle = styled.h1`
        font-size: 18px;
        margin-top: 25px;
        opacity: 1;
        position: relative;
        color: #E3E3EB;
        font-weight: bold;
        text-align: center;
        padding-bottom: 10px;
        display: inline-block;
        width: 90%;
        text-transform: uppercase;
        margin-bottom: 10px;
    
    ${breakpoints.tablet}  {
         font-size: 24px;
        margin-top: 20px;
        opacity: 1;
        position: relative;
 
        flex-direction: row;
        padding-bottom: 10px;
        display: inline-block;
        width: 100%;
        align-self: flex-end;
   
        margin-bottom: 10px;
             text-align: left;
    }
      
    ${breakpoints.desktop}  {
        font-size: 24px;
      
  }
`;

export const ForløbTitle = styled.div`
    font-size: 22px;
    margin-top: 35px;
    opacity: 1;
    position: relative;
    color: #E3E3EB;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
    display: inline-block;
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 10px;
    
    ${breakpoints.tablet}  {
        
    }
      
    ${breakpoints.desktop}  {
        font-size: 30px;
    margin-top: 50px;
    opacity: 1;
    position: relative;
    color: #E3E3EB;
    font-weight: bold;
    text-align: left;
    padding-bottom: 10px;
    display: inline-block;
    width: 70%;
    align-self: flex-end;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
`;

export const ProcessTitle = styled.div`
    flex: 1 1 auto;
    font-size: 16px;
    line-height: 1.5;
    color: ${colors.white};
    display: flex;
    width: 95%;
    font-weight: 400;
    flex-direction: row;
    opacity: 1;
    position: relative;
    justify-content: left;
    margin-bottom: 10px;
    align-self: center;
    padding-bottom: 10px;

    ${breakpoints.tablet}  {
         font-size: 16px;
  }  

    ${breakpoints.desktop}  {
            flex: 1 1 auto;
    font-size: 18px;
    line-height: 1.5;
    color: ${colors.white};
    text-align: left;
    display: flex;
    width: 100%;
    font-weight: 400;
    flex-direction: column;
    opacity: 1;
    position: relative;
    justify-content: flex-end;
    margin-bottom: 10px;
    align-self: flex-end;
    padding-bottom: 10px;
    
      }
`;

export const QuestionList = styled.ul`
    flex: 1 1 auto;
    font-size: 16px;
    line-height: 1.5;
    color: ${colors.white};
    display: flex;
    width: 90%;
    font-weight: 400;
    flex-direction: column;
    opacity: 1;
    position: relative;
    justify-content: center;
    margin-bottom: 10px;
    align-self: center;
  
    padding: 0;
    


    ${breakpoints.tablet}  {
         font-size: 16px;
  }  

    ${breakpoints.desktop}  {
    font-size: 18px;
    text-align: left;
    width: 80%;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 10px;
        align-self: flex-end;
            padding-bottom: 10px;


    
      }
      
`;export const QuestionItem = styled.li`
    flex: 1 1 auto;
    font-size: 16px;
    line-height: 1.5;
    color: ${colors.white};
    display: flex;
    width: 95%;
    font-weight: 400;
    flex-direction: row;
    opacity: 1;
    position: relative;
    justify-content: left;
    margin-bottom: 10px;
    align-self: center;
    padding-bottom: 10px;
 

    ${breakpoints.tablet}  {
         font-size: 16px;
  }  

    ${breakpoints.desktop}  {
    font-size: 18px;
    text-align: left;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 10px;
        align-self: flex-end;

    
      }
`;



export const ContentText = styled.div`
    flex: 1 1 auto;
    font-size: 16px;
    line-height: 1.5;
    color: ${colors.white};
    text-align: left;
    display: flex;
    width: 90%;
    font-weight: 400;
    flex-direction: column;
    opacity: 1;
    position: relative;
    justify-content: center;
    margin-bottom: 10px;
    align-self: center;
    padding-bottom: 10px;
    


    ${breakpoints.tablet}  {
             font-size: 16px;
                    flex: 1 1 auto;

    line-height: 1.5;
    color: ${colors.white};
    text-align: left;
    display: flex;
    width: 70%;
    font-weight: 400;
    flex-direction: column;
    opacity: 1;
    position: relative;
    justify-content: flex-start;
    margin-bottom: 10px;
    align-self: flex-start;
    padding-left: 50px;
    padding-bottom: 10px;
      }
      
    ${breakpoints.desktop}  {
         font-size: 18px;
  }
`;


export const ProcessText = styled.div`
     flex: 1 1 auto;
    font-size: 13px;
    line-height: 1.5;
    color: ${colors.white};
    padding: 10px 30px 15px 0;
    justify-content: left;
    text-align: left;

    width: 100%;
    display: flex;
    
         ${breakpoints.mobile}  {
      font-size: 13px;
  } 

    ${breakpoints.tablet}  {
           font-size: 15px;
      }
      
    ${breakpoints.desktop}  {
        font-size: 18px;


  }
  
`;

export const EnhanceText = styled.text`
    font-weight: 700;
    color: ${colors.light_blue};
`;

export const WhiteEnhance = styled.text`
    font-weight: 700;
    color: ${colors.light_blue};
`;



export const SmallText = styled.text`
    font-weight: 600;
    font-size: 12px;
    color: ${colors.light_blue};
    display: flex;
    justify-content: center;
`;


export const IconContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;

    
    ${breakpoints.tablet} {
        width: 100%;
        flex-direction: row;
    }
    
    ${breakpoints.desktop} {
        width: 100%;
    }

 
`;

export const ProcessItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
    color: ${colors.white};
 `;

export const CardContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: center;
    padding-bottom: 20px;
    border-radius: 3px;   


 
    ${breakpoints.desktop}  {
      width: 70%;
      align-items: center;
  }
    
`;



export const ProcessContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    border-radius: 8px;
    align-content: center;
    margin-bottom: 20px;
    position: relative;
    flex-direction: column;  
    

    
   ${breakpoints.mobile}  {

  } 

   ${breakpoints.tablet}  {
     
      }
      
   ${breakpoints.desktop}  {

  }

`;

export const BackgroundSvg = styled.div`
background-image: url("${Handshake}");
background-repeat: no-repeat;
background-size: auto; 
`;

