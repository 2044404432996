import styled from "styled-components";
import breakpoints from "../../../shared/breakpoints";

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: block;
  margin-top: 0;
  

  & span {
    background: #46bbf2;
    display: block;
    position: relative;
    width: 3.5rem;
    height: .4rem;
    margin-bottom: .7rem;
    transition: all ease-in-out 0.2s;
  }

  .open span:nth-child(2) {
      opacity: 0;
    }

  .open span:nth-child(3) {
    transform: rotate(45deg);
    top: -11px;
  }

  .open span:nth-child(1) {
    transform: rotate(-45deg);
    top: 11px;
  }

`;

export const DropDown = styled.p`
    color: #46bbf2;
    margin: 0;
    
    ${breakpoints.xs_mobile} {
      font-size: 22px;
    }
    
    ${breakpoints.mobile} {
      font-size: 22px;
    }
    
    ${breakpoints.tablet} {
      font-size: 26px;
    }
`;


