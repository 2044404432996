import React from 'react'
import { useSpring, config } from "react-spring";

import Brand from "../brand/brand.component";
import Burgermenu from "../burger_menu/burger_menu.component";
import CollapseMenu from "../collapse_menu/collapse_menu.component";
import {  NavBar, BurgerWrapper, FlexContainer, NavLinks } from "./nav.styles";
import { NavLink } from "react-router-dom";

const Navbar = (props) => {
    const barAnimation = useSpring({
        from: { transform: 'translate3d(0, -10rem, 0)' },
        transform: 'translate3d(0, 0, 0)',
    });

    const linkAnimation = useSpring({
        from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 },
        to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
        delay: 800,
        config: config.wobbly,
    });

    return (
        <>
            <NavBar style={barAnimation}>
                <FlexContainer>
                    <NavLink to="/">
                        <Brand />
                    </NavLink>
                    <NavLinks style={linkAnimation} activeClassName="active">
                        <NavLink to="/" exact={true}>Forside</NavLink>
                        <NavLink to="/proces">Proces</NavLink>
                        <NavLink to="/services">Services</NavLink>
                        <NavLink to="/kontakt">Kontakt</NavLink>
                    </NavLinks>
                    <BurgerWrapper>
                        <Burgermenu
                            navbarState={props.navbarState}
                            handleNavbar={props.handleNavbar}
                        />
                    </BurgerWrapper>
                </FlexContainer>
            </NavBar>
            <CollapseMenu
                navbarState={props.navbarState}
                handleNavbar={props.handleNavbar}
            />
        </>
    )
};

export default Navbar;

