import React, {useEffect} from 'react';
import {
    ButtonContainer,
    DescriptionContainer,
    TextContainer,
    HeaderContainer,
    FirstTitle,
    SloganContainer,
    SecondTitle,
    FirstDiv,
    HeaderTag
} from "./homepage.styles";
import {NavLink} from "react-router-dom";
import './homepage.styles.scss';
import Particles from "react-particles-js";
import MetaTags from 'react-meta-tags';
import Button from '../../components/buttons/general-button/button.component';


const HomePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div>
            <MetaTags>
                <title>Prime Coding | Datadrevne Løsninger</title>
                <meta name="description" content="Vil du have din hjemmeside skal være en del af fremtidens internet, så vælg Prime Coding som dit næste webbureau." />
                <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            </MetaTags>




            <DescriptionContainer>
                <div id="dust">
                    <Particles params={{
                        "particles": {
                            "number": {
                                "value": 1000,
                                "density": {
                                    "enable": true,
                                    "value_area": 800
                                }
                            },
                            "color": {
                                "value": "#60B0DE"
                            },
                            "shape": {
                                "type": "circle",
                                "stroke": {
                                    "width": 0,
                                    "color": "#60B0DE"
                                },

                            },
                            "opacity": {
                                "value": 0.4,
                                "random": true,
                                "anim": {
                                    "enable": true,
                                    "speed": 1,
                                    "opacity_min": 0.1,
                                    "sync": false
                                }
                            },
                            "size": {
                                "value": 3,
                                "random": true,
                                "anim": {
                                    "enable": true,
                                    "speed": 2,
                                    "size_min": 0.1,
                                    "sync": false
                                }
                            },
                            "line_linked": {
                                "enable": false
                            },
                            "move": {
                                "enable": true,
                                "speed": 1,
                                "direction": "none",
                                "random": false,
                                "straight": false,
                                "out_mode": "out",
                                "bounce": false,
                                "attract": {
                                    "enable": false,
                                    "rotateX": 600,
                                    "rotateY": 1200
                                }
                            }
                        },
                        "interactivity": {
                            "detect_on": "canvas",
                            "events": {
                                "onhover": {
                                    "enable": true,
                                },
                                "onclick": {
                                    "enable": false
                                },
                                "resize": false
                            }
                        },
                        "retina_detect": true
                    }}
                    />
                </div>

                <TextContainer>

                    <HeaderContainer>
                        <HeaderTag>
                        <FirstTitle>
                            <FirstDiv>DATADREVNE</FirstDiv>
                        </FirstTitle>
                        <SecondTitle>LØSNINGER</SecondTitle>
                        </HeaderTag>
                    </HeaderContainer>
                    <SloganContainer>
                        Optimer dine arbejdsprocesser
                    </SloganContainer>

                    <ButtonContainer>
                        <NavLink to="/services"><Button className="btn draw-border">Læs mere</Button></NavLink>
                        <NavLink to="/kontakt"><Button className="btn draw-border">Kontakt</Button></NavLink>
                    </ButtonContainer>
                </TextContainer>

            </DescriptionContainer>
        </div>
    )
};
export default HomePage;