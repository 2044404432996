import React, {useEffect} from "react";
import {CookiesContainer} from "../../components/common/Container.component";
import {
    CookiesLink,
    CookiesParagraph,
    CookiesSmallTitle, CookiesTable, CookiesTableContainer, CookiesTextContainer,
    CookiesTitle,
    PersonTitle, PublishContainer, PublishListItem, TableCell, TableRow,
    ThirdPartyListItem
} from "./cookies.styles";


const Cookies = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <CookiesContainer>
                <CookiesTextContainer>
                    <CookiesTitle>Cookie og privatlivspolitik</CookiesTitle>
                    <CookiesSmallTitle>Introduktion</CookiesSmallTitle>
                    <CookiesParagraph>
                        Når du besøger vores website indsamles der oplysninger om dig, som bruges til at tilpasse og
                        forbedre vores indhold og til at øge værdien af de annoncer, der vises på siden. Hvis du ikke
                        ønsker, at der indsamles oplysninger, bør du slette dine cookies og undlade videre brug
                        af websitet. Nedenfor har vi uddybet, hvilke informationer der indsamles, deres formål og hvilke
                        tredjeparter, der har adgang til dem.
                    </CookiesParagraph>
                    <CookiesSmallTitle>Cookies</CookiesSmallTitle>
                    <CookiesParagraph>
                        Websitet anvender ”cookies”, der er en tekstfil, som gemmes på din computer, mobil el.
                        tilsvarende
                        med det formål at genkende den, huske indstillinger, udføre statistik og målrette annoncer.
                        Cookies
                        kan ikke indeholde skadelig kode som f.eks. virus.
                    </CookiesParagraph>
                    <CookiesParagraph>
                        Hvis du sletter eller blokerer cookies vil annoncer kunne blive mindre relevante for dig og
                        optræde
                        hyppigere. Du kan desuden risikere at websitet ikke fungerer optimalt samt at der er indhold, du
                        ikke kan få adgang til.
                    </CookiesParagraph>
                    <CookiesParagraph>
                        Websitet indeholder cookies fra tredjeparter, der i varierende omfang kan omfatte:
                    </CookiesParagraph>
                    <ul>
                        <ThirdPartyListItem>[ Indsæt liste over tredjeparter der sætter cookies på sitet. Se evt.
                            Automatisk
                            opdatering af cookie-oversigt</ThirdPartyListItem>
                        <ThirdPartyListItem>eller henvis evt. til denne liste med: Hyppige tredjeparter ved digital
                            annoncering ]</ThirdPartyListItem>
                    </ul>
                    <PersonTitle>Personoplysninger</PersonTitle>
                    <CookiesSmallTitle>Generelt</CookiesSmallTitle>
                    <CookiesParagraph>
                        Personoplysninger er alle slags informationer, der i et eller andet omfang kan henføres til dig.
                        Når
                        du benytter vores website indsamler og behandler vi en række sådanne informationer. Det sker
                        f.eks.
                        ved alm. tilgang af indhold, hvis du tilmelder dig vores nyhedsbrev, deltager i konkurrencer
                        eller
                        undersøgelser, registrerer dig som bruger eller abonnent, øvrig brug af services eller foretager
                        køb
                        via websitet.
                    </CookiesParagraph>
                    <CookiesParagraph>
                        Vi indsamler og behandler typisk følgende typer af oplysninger: Et unikt ID og tekniske
                        oplysninger
                        om din computer, tablet eller mobiltelefon, dit IP-nummer, geografisk placering, samt hvilke
                        sider
                        du klikker på (interesser). I det omfang du selv giver eksplicit samtykke hertil og selv
                        indtaster
                        informationerne behandles desuden: Navn, telefonnummer, e-mail, adresse og betalingsoplysninger.
                        Det
                        vil typisk være i forbindelse med oprettelse af login eller ved køb.
                    </CookiesParagraph>
                    <CookiesSmallTitle>Sikkerhed</CookiesSmallTitle>
                    <CookiesParagraph>
                        Vi har truffet tekniske og organisatoriske foranstaltninger mod, at dine oplysninger hændeligt
                        eller
                        ulovligt bliver slettet, offentliggjort, fortabt, forringet eller kommer til uvedkommendes
                        kendskab,
                        misbruges eller i øvrigt behandles i strid med lovgivningen.
                    </CookiesParagraph>
                    <CookiesSmallTitle>Formål</CookiesSmallTitle>
                    <CookiesParagraph>
                        Oplysningerne bruges til at identificere dig som bruger og vise dig de annoncer, som vil have
                        størst
                        sandsynlighed for at være relevante for dig, at registrere dine køb og betalinger, samt at kunne
                        levere de services, du har efterspurgt, som f.eks. at fremsende et nyhedsbrev. Herudover
                        anvender vi
                        oplysningerne til at optimere vores services og indhold.
                    </CookiesParagraph>
                    <CookiesSmallTitle>Periode for opbevaring</CookiesSmallTitle>
                    <CookiesParagraph>
                        Oplysningerne opbevares i det tidsrum, der er tilladt i henhold til lovgivningen, og vi sletter
                        dem,
                        når de ikke længere er nødvendige. Perioden afhænger af karakteren af oplysningen og baggrunden
                        for
                        opbevaring. Det er derfor ikke muligt at angive en generel tidsramme for, hvornår informationer
                        slettes.
                    </CookiesParagraph>
                    <CookiesSmallTitle>Videregivelse af oplysninger</CookiesSmallTitle>
                    <CookiesParagraph>
                        Data om din brug af websitet, hvilke annoncer, du modtager og evt. klikker på, geografisk
                        placering,
                        køn og alderssegment m.v. videregives til tredjeparter i det omfang disse oplysninger er kendt.
                        Du
                        kan se hvilke tredjeparter, der er tale om, i afsnittet om ”Cookies” ovenfor. Oplysningerne
                        anvendes
                        til målretning af annoncering.
                    </CookiesParagraph>
                    <CookiesParagraph>
                        Vi benytter herudover en række tredjeparter til opbevaring og behandling af data. Disse
                        behandler
                        udelukkende oplysninger på vores vegne og må ikke anvende dem til egne formål.
                    </CookiesParagraph>
                    <CookiesParagraph>
                        Videregivelse af personoplysninger som navn og e-mail m.v. vil kun ske, hvis du giver samtykke
                        til
                        det. Vi anvender kun databehandlere i EU eller i lande, der kan give dine oplysninger en
                        tilstrækkelig beskyttelse.
                    </CookiesParagraph>
                    <CookiesSmallTitle>Indsigt og klager</CookiesSmallTitle>
                    <CookiesParagraph>
                        Du har ret til at få oplyst, hvilke personoplysninger, vi behandler om dig. Du kan desuden til
                        enhver tid gøre indsigelse mod, at oplysninger anvendes. Du kan også tilbagekalde dit samtykke
                        til,
                        at der bliver behandlet oplysninger om dig. Hvis de oplysninger, der behandles om dig, er
                        forkerte
                        har du ret til at de bliver rettet eller slettet. Henvendelse herom kan ske til: <CookiesLink
                        href="mailto: kontakt@primecoding.dk">kontakt@primecoding.dk</CookiesLink>. Hvis du vil klage
                        over
                        vores behandling af dine personoplysninger, har du også mulighed for at
                        tage kontakt til <CookiesLink href="http://www.datatilsynet.dk/"
                                                      target="_blank">Datatilsynet</CookiesLink>.
                    </CookiesParagraph>
                    <CookiesSmallTitle>Udgiver</CookiesSmallTitle>
                    <PublishContainer>
                        <CookiesParagraph>Websitet ejes og publiceres af:</CookiesParagraph>
                        <ul>
                            <PublishListItem>Prime Coding ApFS</PublishListItem>
                            <PublishListItem>Campusvej 1, 8.,-12</PublishListItem>
                            <PublishListItem>5230 Odense M</PublishListItem>
                            <PublishListItem><CookiesLink href="tel: +4551915564">+45 31 31 97 45</CookiesLink></PublishListItem>
                            <PublishListItem><CookiesLink
                                href="mailto: kontakt@primecoding.dk">kontakt@primecoding.dk</CookiesLink></PublishListItem>
                        </ul>
                    </PublishContainer>
                </CookiesTextContainer>
                <CookiesTableContainer>
                    <CookiesTable>
                        <tr>
                            <th style={{textAlign: "left"}}>Nødvendige (5)</th>
                        </tr>
                        <tr>
                            <th style={{textAlign: "left"}}>Nødvendige cookies hjælper med at gøre en hjemmeside brugbar
                                ved at aktivere grundlæggende
                                funktioner såsom side-navigation og adgang til sikre områder af hjemmesiden. Hjemmesiden
                                kan
                                ikke fungere ordentligt uden disse cookies.
                            </th>
                        </tr>
                    </CookiesTable>
                    <table border="0">
                        <tr>
                            <TableCell><b>Navn</b></TableCell>
                            <TableCell><b>Udbyder</b></TableCell>
                            <TableCell><b>Formål</b></TableCell>
                            <TableCell><b>Udløb</b></TableCell>
                            <TableCell><b>Type</b></TableCell>
                        </tr>
                        <TableRow>
                            <TableCell>__cf__bm</TableCell>
                            <TableCell>Report URI</TableCell>
                            <TableCell>Benyttes til at bestemme, om brugeren er en virkelig person eller en
                                software-robot -
                                Dette muliggør skabelsen af valide rapporter om brugen af hjemmesiden.
                            </TableCell>
                            <TableCell>1 Dag</TableCell>
                            <TableCell>HTTP Cookie</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>CookieConsent</TableCell>
                            <TableCell>Cookiebot</TableCell>
                            <TableCell>Gemmer brugerens cookie-samtykke-tilstand for det aktuelle domæne.</TableCell>
                            <TableCell>1 år</TableCell>
                            <TableCell>HTTP Cookie</TableCell>
                        </TableRow>
                    </table>
                </CookiesTableContainer>
            </CookiesContainer>
        </>
    )
};

export default Cookies;