import styled from 'styled-components';
import breakpoints from "../../shared/breakpoints";


export const DescriptionContainer = styled.div`
        width: 100%;
        justify-content: center;
        align-items: center;
        display:flex;
        flex-direction: column;
        z-index: 10;
        
    ${breakpoints.xs_mobile}{
    margin-top: 20%;
    }  
        
    ${breakpoints.mobile}{
        margin-top: 20%;
    }  
        
    ${breakpoints.tablet}{
        margin-top: 10%;
    } 
        
    ${breakpoints.desktop}{
        margin-top: 5%;
    }
`;

export const TextContainer = styled.div`
        animation-name: zoomIn;
        animation-duration: 1s;
        animation-timing-function: ease-out;
        animation-delay: 1.5s;
        animation-direction: alternate;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-play-state: running;
        transform: scale(0);
        filter: blur(10px);
       
        position: relative;
        justify-content: center;
        align-content: center;
        display: flex;
        flex-direction: column;
        
        ${breakpoints.mobile} {
       
          margin-bottom: 10%;
        }
      
`;


export const HeaderTag = styled.h1`
  
`;

export const HeaderContainer = styled.div`
        margin-top: 50px;
        opacity: 1;
        position: relative;
        color: #E3E3EB;
        font-weight: 800;
        text-align: center;
        display: inline-block;
        border-bottom: 3px solid #E3E3EB;
        letter-spacing: 10px;
        margin-bottom: 10px;
        justify-content: center;
        align-self: center;
        cursor: pointer;
        
    ${breakpoints.mobile}{
        margin-top: 50px;
      
    }  
        
    ${breakpoints.tablet}{
        margin-top: 50px;
    
    } 
        
    ${breakpoints.desktop}{
       
        opacity: 1;
        position: relative;
        color: #E3E3EB;
        font-weight: 800;
        text-align: center;
  
        padding-bottom: 10px;
        display: inline-block;
        border-bottom: 3px solid #E3E3EB;
        letter-spacing: 10px;
        margin-bottom: 10px;
    }
`;

export const FirstTitle = styled.div`
        font-size: 18px;
        position: relative;
        display: inline-block;
        padding: 10px 0 10px 0;
   

        
    ${breakpoints.mobile}{
        font-size: 24px;
    }  
        
    ${breakpoints.tablet}{
        font-size: 40px;
    } 
        
    ${breakpoints.desktop}{
        font-size: 70px;
   
    }
`;

export const FirstDiv = styled.div`
        
        padding: 0 0 10px 10px;

   
`;

export const SecondTitle = styled.div`
         font-size: 20px;
         display: block;
         padding-bottom: 10px;
        
    ${breakpoints.mobile}{
         font-size: 24px;
    }  
        
    ${breakpoints.tablet}{
        font-size: 40px;
    } 
        
    ${breakpoints.desktop}{
        font-size: 70px;

    }
`;

export const SpanContainer = styled.div`
    font-size: 15px;
    letter-spacing: normal;
    display: block;
    font-style: italic;
    position: relative;
    z-index: 2;
    
    &:before, &:after {
      content: "";
      width: 43%;
      height: 3px;
      border-radius: 5px;
      background: #E3E3EB;
      position: absolute;
      right: 0;
      z-index: 1;
      top: .6em;
    }
    &:after {
      left: 0;
    }
        
    ${breakpoints.mobile}{
      font-size: 17px;
    }  
        
    ${breakpoints.tablet}{
        font-size: 20px;
    } 
        
    ${breakpoints.desktop}{
        font-size: 35px;
        letter-spacing: normal;
        display: block;
        font-style: italic;
        position: relative;
        z-index: 2;
    }
`;


export const SloganContainer = styled.div`
        font-family: 'Quicksand', sans-serif;
        text-transform: uppercase;
        color: #FBFBFB;
        margin-top: 10px;
        letter-spacing: 3px;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        padding: 10px 0 10px 0;
        cursor: pointer;
        
    ${breakpoints.mobile}{
         font-size: 13px;
           width: 80%;
           justify-content: center;
           display: flex;
           align-self: center;
    }  
        
    ${breakpoints.tablet}{
        font-size: 18px;
    } 
        
    ${breakpoints.desktop}{
        margin-top: 14px;
        letter-spacing: 3px;
        font-weight: 500;
        font-size: 17px;
        text-align: center;
         width: 90%;
    }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 30px;
`;

