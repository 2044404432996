import React, {useEffect} from 'react';
import {ProcessPageContainer} from "../../components/common/Container.component";
import './process.styles.scss';
import {
    AboutTitle,
    ProcessTitle,
    EnhanceText,
    IconContainer,
    SpecificationIcon,
    MeetingIcon,
    Row,
    StrategicPlanning,
    TechnicalAssessment,
    ButtonItem,
    Column,
    BlueColumn,
    CardContainer,
    BackgroundSvg,
    ProcessSubtitle,
    QuestionList,
    QuestionItem
} from "./process.styles";

import {Helmet} from "react-helmet/es/Helmet";
import {ButtonContainer} from "../homepage/homepage.styles";
import {NavLink} from "react-router-dom";


const ProcessPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <Helmet>
                <title>Prime Coding | Proces</title>
                <meta
                    name="description"
                    content="Ønsker du at bruge data til at optimere din virksomhed, så læs mere om vores arbejdsproces."
                />
            </Helmet>
            <ProcessPageContainer>
                <CardContainer>
                    <BackgroundSvg/>
                    <Row>
                        <Column>
                            <BlueColumn>
                                <AboutTitle>Vores <EnhanceText> samarbejdsproces</EnhanceText></AboutTitle>
                            </BlueColumn>
                        </Column>

                    </Row>
                    <Row>
                        <Column>
                            <BlueColumn>
                                <ProcessTitle>
                                    Vi forstår at, det er en svær beslutning at tage når man skal til at indgå et
                                    samarbejde. Der er så mange spørgsmål og uklarheder. Disse spørgsmål og uklarheder
                                    er grunden til at vi prioritere forarbejdet af hvert projekt meget højt.
                                </ProcessTitle>
                                <ProcessTitle>
                                    Vi ved at ved god planlægning sættes rammerne for et solidt projekt og samarbejde.
                                    Endnu vigtigere betyder god planlægning også at arbejdet bliver udført med så få
                                    udfordringer som muligt.
                                    Her er vores fordybelse af samarbejdsprocessen.
                                </ProcessTitle>
                            </BlueColumn>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <BlueColumn>
                                <IconContainer>
                                    <MeetingIcon/>
                                    <ProcessSubtitle>
                                        Møde og <EnhanceText> forventningsafstemning</EnhanceText></ProcessSubtitle>
                                </IconContainer>
                                <ProcessTitle>
                                    Dette stadie af vores samarbejde og proces indeholder en række spørgsmål til dig og
                                    din virksomhed, som er med til at afdække dine ambitioner og forventninger.
                                </ProcessTitle>
                                <ProcessTitle>
                                    Eksempler på disse spørgsmål kunne være:
                                </ProcessTitle>
                                <QuestionList>
                                    <QuestionItem>
                                        Hvad ønsker du og din virksomhed at opnå, og hvorfor?
                                    </QuestionItem>
                                    <QuestionItem>
                                        Hvordan bliver din succes målt?
                                    </QuestionItem>
                                    <QuestionItem>
                                        Hvad er dine forventninger for vores samarbejde og forløbet?
                                    </QuestionItem>
                                    <QuestionItem>
                                        Hvad er de vigtigste dele af det du gerne vil skabe?
                                    </QuestionItem>
                                </QuestionList>
                            </BlueColumn>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <BlueColumn>
                                <IconContainer>
                                    <TechnicalAssessment/>
                                    <ProcessSubtitle><EnhanceText>Teknisk</EnhanceText> vurdering</ProcessSubtitle>
                                </IconContainer>
                                <ProcessTitle>
                                    Her dykker vi ned i din nuværende tekniske platform samt evnerne på dit hold og
                                    hvordan projektet ser ud kort- og langsigtet.
                                </ProcessTitle>
                                <ProcessTitle>
                                    Under denne tekniske vurdering, kigger vi på hvordan implementeringen af løsningen
                                    kommer til at foregå under de tekniske rammer virksomheden har. Dette har stor betydning
                                    i forhold til de tidsmæssige investeringer hvorvidt der skal bygges videre på den nuværende
                                    tekniske platform, eller der skal implementeres et helt ny platform til projektet.
                                </ProcessTitle>
                                <ProcessTitle>
                                    Under den tekniske vurdering, kan vi stille spørgsmål såsom:
                                </ProcessTitle>
                                <QuestionList>
                                    <QuestionItem>
                                        Hvilke styrker har I, i virksomheden?
                                    </QuestionItem>
                                    <QuestionItem>
                                        Hvilke fordele har din nuværende platform?
                                    </QuestionItem>
                                    <QuestionItem>
                                        Har du en præference med nogle bestemte teknologivalg?
                                    </QuestionItem>
                                    <QuestionItem>
                                        Har dit hold nogle præferencer?
                                    </QuestionItem>
                                    <QuestionItem>
                                        Hvad har i af teknologiske erfaringer på nuværende tidspunkt? Herunder bestemte
                                        programmeringssprog, backend teknologier eller andre produkter/services som i er
                                        afhængige af.
                                    </QuestionItem>
                                    <QuestionItem>
                                        Hvad har din virksomhed af relevante tekniske begrænsninger?
                                    </QuestionItem>
                                </QuestionList>
                            </BlueColumn>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <BlueColumn>
                                <IconContainer>
                                    <StrategicPlanning/>
                                    <ProcessSubtitle>Dokumentering
                                        af <EnhanceText>kravspecifikationer</EnhanceText></ProcessSubtitle>
                                </IconContainer>
                                <ProcessTitle>
                                    Efter teknologierne er gennemgået er det tid til at lave et sammenfattende og
                                    præcist overblik over projektets kravspecifikationer. Dette bliver defineret i et
                                    dokument og det vil indeholde en opremsning af hvad løsningen skal kunne. Dette
                                    dokument indeholder alle de funktionaliteter og indhold som skal udarbejdes.
                                </ProcessTitle>
                                <ProcessTitle>
                                    En kravspecifikation kan være svær at lave. Du har sikkert en masse tanker og idéer,
                                    som vi hjælper dig med at formulere og strukturere. Dette stadie er med til at vi
                                    med vores erfaring kan gå ind og udpege emner som du måske har overset.
                                </ProcessTitle>
                            </BlueColumn>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <BlueColumn>
                                <IconContainer>
                                    <SpecificationIcon/>

                                    <ProcessSubtitle><EnhanceText>Strategisk</EnhanceText> Planlægning</ProcessSubtitle>
                                </IconContainer>

                                <ProcessTitle>
                                    Den strategiske planlægning som vi skaber, består af en plan for hvordan vi mener at,
                                    opgaven skal gribes an udfra al den ovenstående information som vi har indsamlet.
                                    Vores plan vil både indeholde en teknisk fremgangsmetode men også den digitale strategi
                                    som vi i fællesskab er blevet enige om.
                                </ProcessTitle>

                            </BlueColumn>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <BlueColumn>
                                <ProcessTitle>
                                    Vi håber denne uddybbende proces beskrivelse er med til at fremme din forståelse for
                                    vores arbejdsproces, og de forskellige elementer i processen i at bygge en skræddersyet
                                    kickass løsning til dig og dit firma. Vi er altid åbne til en uforpligtende snak omkring
                                    et evt. samarbejde.
                                </ProcessTitle>
                                <ProcessTitle>
                                    <ButtonContainer>
                                        <NavLink to="/kontakt"> <ButtonItem className="btn draw-border">Vil du høre
                                            mere?</ButtonItem></NavLink>
                                    </ButtonContainer>
                                </ProcessTitle>
                            </BlueColumn>
                        </Column>
                    </Row>
                </CardContainer>
            </ProcessPageContainer>
        </>
    );
};

export default ProcessPage;
