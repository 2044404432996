import styled from "styled-components";
import colors from "../../shared/color";
import breakpoints from "../../shared/breakpoints";

export const CookiesTitle = styled.div`
  font-size: 40px;
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  margin: 0 0 2.5% 0;
`;

export const CookiesSmallTitle = styled.div`
  font-size: 24px;
  color: ${colors.white};
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bolder;
  text-decoration: underline;
`;

export const CookiesParagraph = styled.div`
  font-size: 18px;
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  margin: 0 0 20px 0;
`;

export const CookiesLink = styled.a`
  color: ${colors.light_blue};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

export const ThirdPartyListItem = styled.li`
  color: white;
`;

export const PersonTitle = styled(CookiesSmallTitle)`
  margin: 0 0 20px 0;
`;

export const PublishContainer = styled.div`
  color: white;
  font-family: "Source Sans Pro", sans-serif;
`;

export const PublishListItem = styled.li`
  list-style: none;
`;

export const CookiesTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  
  ${breakpoints.xs_mobile} {
    width: 90%;
    padding: 0 20px;
  }
  
  ${breakpoints.desktop} {
    width: 50%;
  }
`;


export const CookiesTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;
  text-align: left;
  color: white;
  border: 2px solid white;
  border-radius: 8px;
  padding: 10px;
  height: 100%;
  
  ${breakpoints.xs_mobile} {
    width: 95%;
    font-size: 12px;
    padding: 5px 0;
  }
  
  ${breakpoints.mobile} {
    font-size: 14px;
  }
  
  ${breakpoints.tablet} {
    font-size: 16px;
    padding: 10px;
  }
  
  ${breakpoints.desktop} {
    width: 40%;
  }
`;

export const CookiesTable = styled.table`
  color: white;
  font-family: "Source Sans Pro", sans-serif;  
  margin-bottom: 25px;
`;

export const TableRow = styled.tr`
padding-right: 20px;
  &:nth-child(even) {
    background: ${colors.light_blue};
    color: white;
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  
  ${breakpoints.xs_mobile} {
    padding: 2.5px;
  }
`;