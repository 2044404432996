import React from 'react';
import ReactCardFlip from 'react-card-flip';
import Administrative from "../../assets/card-assets/card-images/data.png";
import {
    CardTitle,
    CardText,
    CardImage,
    SpecificationsTitle,
    EnhanceText,
    ButtonContainer,
    TitleBlue,
    ServiceCards,
} from "../../pages/service/service.styles";
import {NavLink} from "react-router-dom";
import Button from '../buttons/general-button/button.component';

class AdministrativeWebsiteCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFlipped: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        event.preventDefault();
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
    }

    render() {
        return (
            <ReactCardFlip isFlipped={this.state.isFlipped}
                           flipSpeedBackToFront={0.5}
                           flipSpeedFrontToBack={0.5}
                           flipDirection="vertical">
                <div onClick={this.handleClick}>
                    <CardImage>
                        <ServiceCards src={Administrative} alt="Illustration of data modeller"
                                        title="Data modeller"/>
                    </CardImage>
                    <CardTitle>DATA<TitleBlue>MODELLER</TitleBlue></CardTitle>
                    <CardText>Vi kan hjælpe dig med at opnå en forståelse og ny værdi i din virksomhed ved brug af eksempelvis en
                        <EnhanceText> Machine learning</EnhanceText> model.
                        <br/>
                        <ButtonContainer>
                            <Button serviceCard className="btn draw-border" onClick={this.handleClick}>Læs mere</Button>
                        </ButtonContainer>
                    </CardText>
                </div>

                <div>
                    <div onClick={this.handleClick}>
                        <CardTitle><TitleBlue>MODELLER</TitleBlue></CardTitle>
                        <SpecificationsTitle>
                            Har du brug for en model der bruger dit data på en optimal måde? Det kan vi hjælpe med ved brug af kunstig intelligens.

                        </SpecificationsTitle>

                        <SpecificationsTitle>
                            Vi kan hjælpe dig med at forstå helt basalt hvad kunstig intelligens består af, og hvad det præcist kan gøre
                            for din virksomhed.
                        </SpecificationsTitle>

                        <SpecificationsTitle>
                            Har du brug for hjælp med at viderebygge, teste og lave modeller der passer til dit behov.
                            Lad os tage en uforpligtende snak om hvordan din virksomhed kan effektiviseres.
                        </SpecificationsTitle>
                    </div>

                    <ButtonContainer>
                        <Button serviceCard className="btn draw-border" as="a" href="/kontakt"><NavLink to="/kontakt"> Skal vi starte en dialog?</NavLink></Button>
                    </ButtonContainer>
                </div>

            </ReactCardFlip>
        );
    }
}

export default AdministrativeWebsiteCard;