import React from 'react';
import {  } from 'react-router-dom';

import {  Wrapper, DropDown } from "./burger_menu.styles";

const Burgermenu = (props) => {
    return (
        <Wrapper onClick={props.handleNavbar}>
            <DropDown>Menu</DropDown>
        </Wrapper>
    );
}

export default Burgermenu;
